/* eslint-disable react/jsx-one-expression-per-line */
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import "@stylesPages/Platform.scss";

import Seo from "@components/Seo";
import Header from "@components/Header";
import Footer from "@components/Footer";
import PointsImage from "../assets/images/points-image.png";
import IntroImage from "../assets/images/intro-image.png";

export default function Platform() {
  const { t } = useTranslation("platform");

  return (
    <>
      <Seo title={t("title")} />
      <Header />
      <main className="platform">
        <section className="platform__hero">
          <div className="platform__hero-container">
            <h2 className="platform__hero-title">{t("title")}</h2>
          </div>
        </section>
        <section className="platform__intro">
          <div className="platform__intro-container">
            <div className="platform__intro-box">
              <img src={IntroImage} alt="" className="platform__intro-image" />
            </div>
            <div className="platform__intro-box">
              <h2 className="platform__intro-title">{t("intro-title")}</h2>
              <p className="platform__intro-paragraph">
                {t("intro-paragraph")}
              </p>
            </div>
          </div>
        </section>
        <section className="platform__points">
          <div className="platform__points-container">
            <h2 className="platform__points-title">{t("points-title")}</h2>
            <div className="platform__points-grid">
              <div className="platform__points-box">
                <ul className="platform__points-list">
                  <li className="platform__points-item platform__points-item--right">
                    <span className="platform__points-bold">
                      {t("points-title-one")}{" "}
                    </span>
                    {t("points-text-one")}
                  </li>
                  <li className="platform__points-item platform__points-item--right">
                    <span className="platform__points-bold">
                      {t("points-title-two")}{" "}
                    </span>
                    {t("points-text-two")}
                  </li>
                  <li className="platform__points-item platform__points-item--right">
                    <span className="platform__points-bold">
                      {t("points-title-three")}{" "}
                    </span>
                    {t("points-text-three")}
                  </li>
                  <li className="platform__points-item platform__points-item--right">
                    <span className="platform__points-bold">
                      {t("points-title-four")}{" "}
                    </span>
                    {t("points-text-four")}
                  </li>
                </ul>
              </div>
              <div className="platform__points-box platform__points-box--image">
                <img
                  src={PointsImage}
                  alt="Points"
                  className="platform__points-image"
                />
              </div>
              <div className="platform__points-box">
                <ul className="platform__points-list">
                  <li className="platform__points-item platform__points-item--left">
                    <span className="platform__points-bold">
                      {t("points-title-five")}{" "}
                    </span>
                    {t("points-text-five")}
                  </li>
                  <li className="platform__points-item platform__points-item--left">
                    <span className="platform__points-bold">
                      {t("points-title-six")}{" "}
                    </span>
                    {t("points-text-six")}
                  </li>
                  <li className="platform__points-item platform__points-item--left">
                    <span className="platform__points-bold">
                      {t("points-title-seven")}{" "}
                    </span>
                    {t("points-text-seven")}
                  </li>
                  <li className="platform__points-item platform__points-item--left">
                    <span className="platform__points-bold">
                      {t("points-title-eight")}{" "}
                    </span>
                    {t("points-text-eight")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="platform__add">
          <div className="platform__add-container">
            <h2 className="platform__add-title">{t("add-title")}</h2>
            <div className="platform__add-grid">
              <article className="platform__add-item">
                <p className="platform__add-text-wrapper">
                  <span className="platform__add-bold">
                    {t("add-title-one-bold")}{" "}
                  </span>
                  {t("add-title-one")}
                </p>
              </article>
              <article className="platform__add-item">
                <p className="platform__add-text-wrapper">
                  <span className="platform__add-bold">
                    {t("add-title-two-bold")}{" "}
                  </span>
                  {t("add-title-two")}
                </p>
              </article>
              <article className="platform__add-item">
                <p className="platform__add-text-wrapper">
                  <span className="platform__add-bold">
                    {t("add-title-three-bold")}{" "}
                  </span>
                  {t("add-title-three")}
                </p>
              </article>
              <article className="platform__add-item">
                <p className="platform__add-text-wrapper">
                  <span className="platform__add-bold">
                    {t("add-title-four-bold")}{" "}
                  </span>
                  {t("add-title-four")}
                </p>
              </article>
              <article className="platform__add-item">
                <p className="platform__add-text-wrapper">
                  <span className="platform__add-bold">
                    {t("add-title-five-bold")}{" "}
                  </span>
                  {t("add-title-five")}
                </p>
              </article>
              <article className="platform__add-item">
                <p className="platform__add-text-wrapper">
                  <span className="platform__add-bold">
                    {t("add-title-six-bold")}
                  </span>
                  {t("add-title-six")}
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="platform__bottom">
          <div className="platform__bottom-container">
            <p className="platform__bottom-text">{t("bottom-text")}</p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
